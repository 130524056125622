<template>
  <div>
    <v-navigation-drawer class="d-print-none" v-model="$store.state.drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
     <!-- {{search_links}} -->
     <div v-for="(child, i) in items" :key="i">
        <router-link
          class="sidebar-link"
          :to="{ name: 'role-links', params: { role:child.name }}">
          <v-list-item class="sidebar-link-item">
            <!--<v-list-item-action v-if="child.icon">
        <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>-->
            <v-list-item-content >
              <v-list-item-title style="margin-left: 70px;">{{ child.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      items: [],
    };
  },
  methods: {
    
  },
  mounted() {
    axios
      .get("/login/slidebardashboard")
      .then(res => {
        this.items = res.data.links;
      })
      .catch(error => {
        window.console.log(error);
        this.$router.push("/");
      });
  }
};
</script>
<style scoped>
.sidebar-link {
  text-decoration: none;
}
.sidebar-link-item:hover {
  background-color: rgb(202, 206, 209) !important;
}

</style>