<template>
  <v-app id="inspire">
       <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <!-- <v-content> -->
      <v-skeleton-loader class="ma-3 pa-2" v-if="loading" v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>

      <v-container v-else>
       
             <v-data-table
            :headers="headers"
            :items="company_list"
            class="elevation-1"
            :search="search"
            >
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                        <v-toolbar-title>Company Scheduling</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="search"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                        <!-- <span style="width: 40px"></span>
                            <v-switch
                            v-model="singleExpand"
                            label="Single expand"
                            class="mt-2"
                            ></v-switch> -->
                        </v-toolbar>
                    </template>
                        
                     <template v-slot:item.info="{ item }">
                        <span @click="finddetails(item)"> <span style="font-size:30px;" class="mdi mdi-information-outline"></span></span>
                    </template>
                    
                    <template v-slot:item.regstartdatetime="{ item }">
                        {{item.regStartdate}} {{item.regStarttime}}
                    </template>
                    <template v-slot:item.regenddatetime="{ item }">
                        {{item.regEnddate}}  {{item.regEndtime}}
                    </template>

                    <template v-slot:item.criteria="{ item }">
                <div>
                    <v-avatar style="" size="45" class="edit-avatar">
                      <v-icon class="mr-2" style="font-size:30px;" color="success" @click="showcriteria(item)">mdi-eye-outline</v-icon>
                  </v-avatar>
                  </div>
                </template>
                <template v-slot:item.selection="{ item }">
                  <div>
                    <v-avatar style="" size="45" class="edit-avatar">
                      <v-icon class="mr-2" style="font-size:30px;" color="success" @click="showselection(item)">mdi-eye-outline</v-icon>
                  </v-avatar>
                  </div>
                </template>
                <template v-slot:top>
                          <v-toolbar flat color="white">                   
                              <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
                              </v-text-field>
                              <v-spacer></v-spacer>
                              <span style="width: 20px"></span>
                              <v-dialog v-model="criteriadialog" max-width="500px">          
                                  <v-card>
                                      <v-form v-model="valid">
                                          <v-card-title>
                                              <v-row justify="space-between" > 
                                                <span class="headline" style="margin-left:20px">Criteria</span>
                                                <v-btn  icon dark @click="criteriadialog = false">
                                                <v-icon color="black">mdi-close</v-icon>
                                                </v-btn>
                                              </v-row>                              
                                          </v-card-title>
                                          <v-card-text>
                                              <v-container>
                                                  <v-data-table
                                                    :headers="headers1"
                                                    :items="criteria_list"
                                                    :search="search"
                                                  ></v-data-table> 
                                              </v-container>
                                          </v-card-text>
                                      </v-form>
                                  </v-card>
                              </v-dialog>
                              <v-dialog v-model="selectiondialog" max-width="500px">          
                                  <v-card>
                                      <v-form v-model="valid">
                                          <v-card-title>
                                              <v-row justify="space-between" > 
                                                <span class="headline" style="margin-left:20px">Selection Procedure</span>
                                                <v-btn  icon dark @click="selectiondialog = false">
                                                <v-icon color="black">mdi-close</v-icon>
                                                </v-btn>
                                              </v-row>                              
                                          </v-card-title>
                                          <v-card-text>
                                              <v-container>
                                                  <v-data-table
                                                    :headers="headers2"
                                                    :items="selectionprocedure_list"
                                                    :search="search"
                                                  >

                                    




                                                  <template v-slot:item.isfinal="{ item }">
                                                      <div>
                                                        <span v-if="item.final == true">
                                                              <v-icon class="mr-2 v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                                                          </span>
                                                          <span style="margin-left:8px" v-else>
                                                            --
                                                          </span>
                                                        </div>
                                                      </template>
                                                  
                                                  </v-data-table> 
                                              </v-container>
                                          </v-card-text>
                                      </v-form>
                                  </v-card>
                              </v-dialog>
                          </v-toolbar>
                  </template>




            </v-data-table>

     
      </v-container>
    <!-- </v-content> -->
  </v-app>
</template>
<script>
import epsSidebar from "../shared/Sidebar";
import epsHeader from "../shared/Header";
import axios from "axios";

export default {
  data: () => ({
        dialog: false,
     search: "",
     criteria_list:[],
    company_list : [],
     valid: false,
     expanded: [],
     singleExpand: false,
    selectionprocedure_list:[],
    drawer: null,
    studentcounts: null,
    usertype:null,
    criteriadialog:false,
    selectiondialog:false,
    headers: [
      { text: "Company", value: "company", sortable: true },
      { text: "Company Info", value: "info", sortable: true },
      // { text: "branch", value: "tpoprogram", sortable: true },
       { text:  "Criteria",value: "criteria", sortable: true },
      { text:  "Selection Procedure",value: "selection", sortable: true },
      { text: "Registration Start Time", value: "regstartdatetime", sortable: true },
      { text: "Registration End Time", value: "regenddatetime", sortable: true },
      { text:  "Max Package",value: "maxPackage", sortable: true },
      { text:  "Min Package",value: "minPackage", sortable: true },
      // { text:  "From:Schedule Date",value: "from_schedule_date", sortable: true },
      // { text:  "To:Schedule Date",value: "to_schedule_date", sortable: true },
      { text:  "Placement Type",value: "placementtype", sortable: true },
      { text:  "Academic Year",value: "academicyear", sortable: true },
     
    ],
    headers1:[
      { text: "Number", value: "number", sortable: true },
      { text: "Degree", value: "degree", sortable: true },
      { text:  "Criteria",value: "marks", sortable: true },
    ],
     headers2:[
      { text: "Number", value: "number", sortable: true },
      { text: "Round", value: "round", sortable: true },
      { text:  "Isfinal",value: "isfinal", sortable: true },
    ],
    color_array: [
      "#3CDBBB",
      "#FFBD5D",
      "#5EA9FF",
      "#F98B50",
      "#DAF950",
      "#86F950",
      "#50D3F9",
      "#C850F9",
      "#C850F9",
    ],
    loading:false,
  }),
 
  components: {
    epsSidebar: epsSidebar,
    epsHeader: epsHeader,
  },
  mounted() {
    this.newschedulesdcopanies();
      //   axios
      // .post("/login/getadmindashboard")
      // .then((res) => {
      //   //console.log(res);
      //   if (res.data.msg == "200") {
      //     this.studentcounts = res.data.students_count;
      //     this.usertype = res.data.usertype;
      //     if(this.usertype == 'Student' ){
      //       //console.log("usertype : ");
      //       //console.log(this.usertype);
      //       this.newschedulesdcopanies();
      //     }
      //   } else {
      //     //window.console.log(res.data.msg);
      //   }
      // })
      // .catch((error) => {
      //   this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
      //   //window.console.log(error);
      // })
      // .finally(() => {
      //   // 'use strict';
      //   var overlay = false;
      // });
      
  },
    methods: {
   
    newschedulesdcopanies()
    {this.loading=true;
     // alert("in newschedulesdcopanies")
       axios
      .post("/TPOCompanyScheduling/newschedulesdcopanies")
      .then((res) => {
        if (res.data.status == "200") {
          //console.log(res.data.company_list);
          this.company_list = res.data.company_list;
          this.loading=false;
          
        } else if(res.data.status == "404") {
          this.showSnackbar("#b71c1c",res.data.msg);
          this.loading=false;
        }else{
            this.showSnackbar("#b71c1c","Something Went Wrong");
            this.loading=false;
        }
      })
      
    },
    // showcriteria(item){

        
    // },
    showcriteria(item){
    //  alert("in showcriteria")
      const data={
          Id : item.id
      };
       axios
      .post("/TPOCompanyScheduling/showcriteria",data)
      .then((res) => {
        if (res.data.status == "200") {
          //console.log(res.data.company_list);
          this.criteria_list = res.data.criteria_list;
          this.criteriadialog = true
        } else if(res.data.status == "404") {
          this.showSnackbar("#b71c1c",res.data.msg);
        }else{
            this.showSnackbar("#b71c1c","Something Went Wrong");
        }
      })
      
    },
    finddetails(item) {
     this.$store.commit("setcompany_id", item.id);
       this.$router.push({ name: "company-info" },'_blank');

      // let routeData = this.$router.resolve({name: "company-info"});
      //       window.open(routeData.href, '_blank');

      //   var routerpush = this.$router.resolve({
      //     name:"company-info",
          
      // })
      // // console.log(routerpush)
      //  window.open(routerpush.href, '_blank');
      
    },

    showselection(item){
     // alert("in showselection")
      const data={
          Id : item.id
      };
       axios
      .post("/TPOCompanyScheduling/showselectionproc",data)
      .then((res) => {
        if (res.data.status == "200") {
          //console.log(res.data.selectionprocedure_list);
          this.selectionprocedure_list = res.data.selectionprocedure_list;
          this.selectiondialog = true
        } else if(res.data.status == "404") {
          this.showSnackbar("#b71c1c",res.data.msg);
        }else{
            this.showSnackbar("#b71c1c","Something Went Wrong");
        }
      })
      
    },




  

  },
};
</script>

<style scoped>
.center {
  margin: auto;
  width: 50%;
  padding: 30px 0;
  text-align: center;
}
.center2 {
  margin: auto;
  width: 50%;
  padding: 40px 0;
  text-align: left;
}
.question{
    margin:8px; height:100%;padding-top:15px;padding-left:15px;padding-right:15px;
}
</style>